import React from 'react';
import { Link } from 'gatsby';
import { socialLinks } from 'src/config';
import Layout from 'src/components/layout';
import {
  SignUpLayoutBodyWrapper,
  HeaderLogo,
  JonniLogo,
  SignUpLayoutStepContainer,
  StepWrapper,
  SignUpLayoutBackgroundWrapper,
  SignUpLayoutBlueBackground,
  BlueWhatsappIcon,
  HeaderContainer,
} from 'src/styles/signup/signupSteps';
import SignupTopbar from 'src/layouts/components/SignupMobileTopbar';
import MaintenancePageContent from 'src/components/Maintenance/MaintenancePageContent';

const MaintenancePage = () => {
  return (
    <Layout>
      <SignupTopbar />
      <SignUpLayoutBackgroundWrapper>
        <SignUpLayoutBlueBackground />
      </SignUpLayoutBackgroundWrapper>
      <SignUpLayoutBodyWrapper>
        <SignUpLayoutStepContainer>
          <HeaderContainer>
            <HeaderLogo>
              <Link to={'https://www.' + process.env.GATSBY_JONNI_DOMAIN}>
                <JonniLogo />
              </Link>
            </HeaderLogo>
            <a href={socialLinks.whatsapp} target="_blank" rel="noreferrer">
              <BlueWhatsappIcon />
            </a>
          </HeaderContainer>
          <StepWrapper>
            <MaintenancePageContent />
          </StepWrapper>
        </SignUpLayoutStepContainer>
      </SignUpLayoutBodyWrapper>
    </Layout>
  );
};

export default MaintenancePage;
