import styled from '@emotion/styled';
import { useTheme } from '@mui/material/styles';
import lock from 'assets/lock.svg';
import { graphql } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import React from 'react';
import { StyledDiv, StyledTypography } from 'src/styles/styledComponents';

const LockIcon = styled(lock)`
  width: 45px;
  height: 45px;
`;

const MaintenancePageContent = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <StyledDiv display="flex" flexdirection="column" alignitems="center" margintop={'200px'} marginbottom={'200px'}>
      <StyledDiv marginbottom={theme.spacing(1)}>
        <LockIcon />
      </StyledDiv>
      <StyledTypography variant="h3" marginbottom={theme.spacing(1.5)}>
        {t('maintenance.title')}
      </StyledTypography>
      <StyledTypography variant="body1" marginbottom={theme.spacing(1.5)}>
        {t('maintenance.content')}
      </StyledTypography>
    </StyledDiv>
  );
};

export default MaintenancePageContent;
