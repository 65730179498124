exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-aboutus-tsx": () => import("./../../../src/pages/aboutus.tsx" /* webpackChunkName: "component---src-pages-aboutus-tsx" */),
  "component---src-pages-accessibility-tsx": () => import("./../../../src/pages/accessibility.tsx" /* webpackChunkName: "component---src-pages-accessibility-tsx" */),
  "component---src-pages-adjustmentquestionnaire-tsx": () => import("./../../../src/pages/adjustmentquestionnaire.tsx" /* webpackChunkName: "component---src-pages-adjustmentquestionnaire-tsx" */),
  "component---src-pages-features-tsx": () => import("./../../../src/pages/features.tsx" /* webpackChunkName: "component---src-pages-features-tsx" */),
  "component---src-pages-frequentlyaskedquestions-tsx": () => import("./../../../src/pages/frequentlyaskedquestions.tsx" /* webpackChunkName: "component---src-pages-frequentlyaskedquestions-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-lp-existingbusiness-tsx": () => import("./../../../src/pages/lp/existingbusiness.tsx" /* webpackChunkName: "component---src-pages-lp-existingbusiness-tsx" */),
  "component---src-pages-lp-osekmurshe-tsx": () => import("./../../../src/pages/lp/osekmurshe.tsx" /* webpackChunkName: "component---src-pages-lp-osekmurshe-tsx" */),
  "component---src-pages-lp-osekpatur-tsx": () => import("./../../../src/pages/lp/osekpatur.tsx" /* webpackChunkName: "component---src-pages-lp-osekpatur-tsx" */),
  "component---src-pages-magazine-deductible-expense-tsx": () => import("./../../../src/pages/magazine/deductible-expense.tsx" /* webpackChunkName: "component---src-pages-magazine-deductible-expense-tsx" */),
  "component---src-pages-magazine-differences-between-patur-and-murshe-tsx": () => import("./../../../src/pages/magazine/differences-between-patur-and-murshe.tsx" /* webpackChunkName: "component---src-pages-magazine-differences-between-patur-and-murshe-tsx" */),
  "component---src-pages-magazine-opening-a-business-tsx": () => import("./../../../src/pages/magazine/opening-a-business.tsx" /* webpackChunkName: "component---src-pages-magazine-opening-a-business-tsx" */),
  "component---src-pages-magazine-selfemployed-and-employed-tsx": () => import("./../../../src/pages/magazine/selfemployed-and-employed.tsx" /* webpackChunkName: "component---src-pages-magazine-selfemployed-and-employed-tsx" */),
  "component---src-pages-magazine-tsx": () => import("./../../../src/pages/magazine.tsx" /* webpackChunkName: "component---src-pages-magazine-tsx" */),
  "component---src-pages-mobile-privacypolicy-tsx": () => import("./../../../src/pages/mobile/privacypolicy.tsx" /* webpackChunkName: "component---src-pages-mobile-privacypolicy-tsx" */),
  "component---src-pages-mobile-termsofuse-tsx": () => import("./../../../src/pages/mobile/termsofuse.tsx" /* webpackChunkName: "component---src-pages-mobile-termsofuse-tsx" */),
  "component---src-pages-plans-tsx": () => import("./../../../src/pages/plans.tsx" /* webpackChunkName: "component---src-pages-plans-tsx" */),
  "component---src-pages-privacypolicy-tsx": () => import("./../../../src/pages/privacypolicy.tsx" /* webpackChunkName: "component---src-pages-privacypolicy-tsx" */),
  "component---src-pages-signup-accountant-existence-tsx": () => import("./../../../src/pages/signup/accountant-existence.tsx" /* webpackChunkName: "component---src-pages-signup-accountant-existence-tsx" */),
  "component---src-pages-signup-authentication-tsx": () => import("./../../../src/pages/signup/authentication.tsx" /* webpackChunkName: "component---src-pages-signup-authentication-tsx" */),
  "component---src-pages-signup-authorities-identification-details-tsx": () => import("./../../../src/pages/signup/authorities-identification-details.tsx" /* webpackChunkName: "component---src-pages-signup-authorities-identification-details-tsx" */),
  "component---src-pages-signup-authorities-identification-upload-tsx": () => import("./../../../src/pages/signup/authorities-identification-upload.tsx" /* webpackChunkName: "component---src-pages-signup-authorities-identification-upload-tsx" */),
  "component---src-pages-signup-bank-document-upload-tsx": () => import("./../../../src/pages/signup/bank-document-upload.tsx" /* webpackChunkName: "component---src-pages-signup-bank-document-upload-tsx" */),
  "component---src-pages-signup-billing-details-tsx": () => import("./../../../src/pages/signup/billing-details.tsx" /* webpackChunkName: "component---src-pages-signup-billing-details-tsx" */),
  "component---src-pages-signup-business-details-tsx": () => import("./../../../src/pages/signup/business-details.tsx" /* webpackChunkName: "component---src-pages-signup-business-details-tsx" */),
  "component---src-pages-signup-business-industries-tsx": () => import("./../../../src/pages/signup/business-industries.tsx" /* webpackChunkName: "component---src-pages-signup-business-industries-tsx" */),
  "component---src-pages-signup-business-status-tsx": () => import("./../../../src/pages/signup/business-status.tsx" /* webpackChunkName: "component---src-pages-signup-business-status-tsx" */),
  "component---src-pages-signup-business-type-tsx": () => import("./../../../src/pages/signup/business-type.tsx" /* webpackChunkName: "component---src-pages-signup-business-type-tsx" */),
  "component---src-pages-signup-done-tsx": () => import("./../../../src/pages/signup/done.tsx" /* webpackChunkName: "component---src-pages-signup-done-tsx" */),
  "component---src-pages-signup-employees-existence-tsx": () => import("./../../../src/pages/signup/employees-existence.tsx" /* webpackChunkName: "component---src-pages-signup-employees-existence-tsx" */),
  "component---src-pages-signup-gift-details-tsx": () => import("./../../../src/pages/signup/gift-details.tsx" /* webpackChunkName: "component---src-pages-signup-gift-details-tsx" */),
  "component---src-pages-signup-index-tsx": () => import("./../../../src/pages/signup/index.tsx" /* webpackChunkName: "component---src-pages-signup-index-tsx" */),
  "component---src-pages-signup-monthly-income-tsx": () => import("./../../../src/pages/signup/monthly-income.tsx" /* webpackChunkName: "component---src-pages-signup-monthly-income-tsx" */),
  "component---src-pages-signup-verification-tsx": () => import("./../../../src/pages/signup/verification.tsx" /* webpackChunkName: "component---src-pages-signup-verification-tsx" */),
  "component---src-pages-signup-welcome-tsx": () => import("./../../../src/pages/signup/welcome.tsx" /* webpackChunkName: "component---src-pages-signup-welcome-tsx" */),
  "component---src-pages-termsofuse-tsx": () => import("./../../../src/pages/termsofuse.tsx" /* webpackChunkName: "component---src-pages-termsofuse-tsx" */)
}

