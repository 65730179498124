import { CircularProgress } from '@mui/material';
import { graphql, navigate } from 'gatsby';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { GoogleReCaptcha, GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useRecoilValue } from 'recoil';
import MaintenancePage from 'src/components/Maintenance/MaintenancePage';
import SideSteps from 'src/components/Steps/SideSteps';
import StepsMobile from 'src/components/Steps/StepsMobile';
import Layout from 'src/components/layout';
import 'src/config/amplify';
import useMaintenance from 'src/hooks/useMaintenance';
import SignupLayoutHeader from 'src/layouts/components/SignupLayoutHeader';
import SignupMobileTopbar from 'src/layouts/components/SignupMobileTopbar';
import { Steps, pathToStep, stepsAtom } from 'src/recoil/steps/atoms';
import {
  CustomToastifyContainer,
  LoaderWrapper,
  SignUpLayoutBackgroundWrapper,
  SignUpLayoutBlueBackground,
  SignUpLayoutBodyWrapper,
  SignUpLayoutStepContainer,
  StepWrapper,
  StyledOverlay,
} from 'src/styles/signup/signupSteps';

interface SignUpProps {
  children: React.ReactElement;
  path: string;
}

export const SignUpLayout: React.FC<SignUpProps> = ({ path, children }) => {
  const [token, setToken] = useState('');
  const { isOnMaintenance } = useMaintenance();
  const activeStep = pathToStep(path);
  const { currentUser, currentStep } = useRecoilValue(stepsAtom);

  useEffect(() => {
    window.addEventListener('popstate', async function (event) {
      await navigate('/');
    });
  }, []);

  const onVerify = useCallback((token: string) => {
    setToken(token);
  }, []);

  const shouldRenderStep = useMemo(() => {
    return (
      (currentUser?.id !== '' ||
        activeStep === Steps.WELCOME ||
        activeStep === Steps.AUTHENTICATION ||
        activeStep === Steps.VERIFICATION) &&
      currentStep === activeStep
    );
  }, [currentUser, activeStep, currentStep]);

  return isOnMaintenance ? (
    <MaintenancePage />
  ) : (
    <GoogleReCaptchaProvider reCaptchaKey={process.env.GATSBY_GOOGLE_RECAPTCHA_SITE_KEY!}>
      <Layout>
        <SignupMobileTopbar />
        <SignUpLayoutBackgroundWrapper>
          <SignUpLayoutBlueBackground />
        </SignUpLayoutBackgroundWrapper>

        <SignUpLayoutBodyWrapper>
          <SideSteps activeStep={activeStep} />
          <StepsMobile activeStep={activeStep} />

          <SignUpLayoutStepContainer>
            <SignupLayoutHeader />

            <SignUpLayoutLoaderComponent />

            {shouldRenderStep ? <StepWrapper>{children}</StepWrapper> : null}
          </SignUpLayoutStepContainer>
        </SignUpLayoutBodyWrapper>
      </Layout>

      <CustomToastifyContainer>
        <ToastContainer />
      </CustomToastifyContainer>
      <GoogleReCaptcha onVerify={onVerify} />
    </GoogleReCaptchaProvider>
  );
};

const SignUpLayoutLoaderComponent = () => {
  const { loading } = useRecoilValue(stepsAtom);

  return loading ? (
    <StyledOverlay>
      <LoaderWrapper>
        <CircularProgress size={60} />
      </LoaderWrapper>
    </StyledOverlay>
  ) : null;
};

export default SignUpLayout;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { ns: { in: ["translation", "about-page"] }, language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
